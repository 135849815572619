import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";

const getIcon = (name, sx = {}) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{width: 1, height: 1, ...sx}}/>;

const getIconifyIcon = (name, sx = {}) => <Iconify icon={name} sx={sx}/>

const ICONS = {
    serviceSink: (sx = {}) => getIconifyIcon('healthicons:emergency-operations-center', sx),
    communication: (sx = {}) => getIconifyIcon('maki:communications-tower', sx),
    finance: (sx = {}) => getIconifyIcon('hugeicons:pay-by-check', sx),
    prePayroll: (sx = {}) => getIconifyIcon('hugeicons:pay-by-check', sx),
    expenses: (sx = {}) => getIconifyIcon('mdi:account-payment-outline', sx),
    filing: (sx = {}) => getIconifyIcon('mdi:filing-cabinet', sx),
    appAssets: getIconifyIcon('fluent:app-generic-20-filled'),
    companies: getIconifyIcon('ic:twotone-business'),
    packages: getIconifyIcon('mdi:package-variant'),
    opportunity: (sx = {}) => getIconifyIcon('ep:opportunity', sx),
    payroll: (sx = {}) => getIconifyIcon('mdi:steering', sx),
    drivers: (sx = {}) => getIconifyIcon('mdi:steering', sx),
    driversSchedule: (sx = {}) => getIconifyIcon('mdi:calendar-clock-outline', sx),
    blog: getIcon('ic_blog'),
    role: getIconifyIcon('academicons:closed-access-square'),
    cart: getIcon('ic_cart'),
    service: (sx = {}) => getIconifyIcon('carbon:vehicle-services', sx),
    crm: getIconifyIcon('ic:twotone-business'),
    resourceCenter: getIconifyIcon('hugeicons:resources-add'),
    chat: (sx = {}) => getIconifyIcon('wpf:chat', sx),
    settings: (sx = {}) => getIconifyIcon('tabler:settings-cog', sx),
    rate: (sx = {}) => getIconifyIcon('solar:tag-price-bold', sx),
    cost: (sx = {}) => getIconifyIcon('ic:outline-price-change', sx),
    jobs: (sx = {}) => getIconifyIcon('carbon:batch-job', sx),
    mail: getIcon('ic_mail'),
    part: (sx = {}) => getIconifyIcon('fluent:box-toolbox-24-filled', sx),
    user: getIcon('ic_user'),
    users: (sx = {}) => getIconifyIcon('mdi:account-multiple', sx),
    hr: getIconifyIcon('mdi:account-group'),
    operations: getIconifyIcon('mdi:cog'),
    resources: getIconifyIcon('mdi:archive'),
    pricingAndOfferings: getIconifyIcon('mdi:tag-multiple'),
    hoo: (sx = {}) => getIconifyIcon('ant-design:clock-circle-twotone', sx),
    content: (sx = {}) => getIconifyIcon('eos-icons:machine-learning-outlined', sx),
    coverage: (sx = {}) => getIconifyIcon('emojione-monotone:world-map', sx),
    pricingPackage: (sx = {}) => getIconifyIcon('mdi:package-variant-closed', sx),
    fleet: (sx = {}) => getIconifyIcon('game-icons:tow-truck', sx),
    facilities: (sx = {}) => getIconifyIcon('tdesign:building-4', sx),
    documents: (sx = {}) => getIconifyIcon('mingcute:documents-line', sx),
    contracts: (sx = {}) => getIconifyIcon('clarity:contract-line', sx),
    dispatch: (sx = {}) => getIconifyIcon('fluent:share-screen-person-overlay-28-regular', sx),
    locations: (sx = {}) => getIconifyIcon('entypo:location', sx),
    customerAccounts: (sx = {}) => getIconifyIcon('mdi:card-account-phone-outline', sx),
    businessAccounts: (sx = {}) => getIconifyIcon('mdi:card-account-phone-outline', sx),
    network: (sx = {}) => getIconifyIcon('carbon:content-delivery-network', sx),
    support: getIconifyIcon('ic:round-support-agent'),
    kanban: (sx = {}) => getIconifyIcon('fluent:grid-kanban-20-filled', sx),
    claims: getIcon('ic_kanban'),
    banking: (sx = {}) => getIconifyIcon('streamline:bank', sx),
    bonusTracker: (sx = {}) => getIconifyIcon('la:piggy-bank', sx),
    booking: getIcon('ic_booking'),
    invoice: (sx = {}) => getIconifyIcon('gg:file-document', sx),
    license: getIconifyIcon('carbon:license-maintenance'),
    product: getIconifyIcon('icon-park-outline:ad-product'),
    integration: (sx = {}) => getIconifyIcon('mdi:transit-connection-variant', sx),
    system: getIconifyIcon('tdesign:system-setting'),
    calendar: (sx = {}) => getIconifyIcon('la:calendar', sx),
    ecommerce: getIcon('ic_ecommerce'),
    businessHealth: (sx = {}) => getIconifyIcon('mage:health-square', sx),
    analytics: (sx = {}) => getIconifyIcon('fluent-mdl2:analytics-report', sx),
    dashboard: (sx = {}) => getIconifyIcon('file-icons:dashboard', sx),
    csi: (sx = {}) => getIconifyIcon('fluent:video-person-star-20-filled', sx),
    insurance: (sx = {}) => getIconifyIcon('file-icons:dashboard', sx),
};

export default ICONS;
