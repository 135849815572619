import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    payrollRatesLoading: null,
    payrollRatesCollected: null,
    payrollRates: [],
    payrollSettingsLoading: null,
    payrollSettingsCollected: null,
    payrollSettings: null
};

const slice = createSlice({
    name: 'hoo',
    initialState,
    reducers: {
        // START LOADING
        startPayrollRatesLoading(state) {
            state.payrollRatesLoading = true;
        },

        startPayrollSettingsLoading(state) {
            state.payrollSettingsLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.payrollRatesLoading = false;
            state.payrollSettingsLoading = false;
            state.payrollRatesCollected = true;
            state.error = action.payload;
        },

        // HAS ERROR
        hasSettingsError(state, action) {
            state.payrollSettingsLoading = false;
            state.payrollSettingsCollected = true;
            state.error = action.payload;
        },

        getPayrollRatesSuccess(state, action) {
            state.payrollRatesLoading = false;
            state.payrollRatesCollected = true;
            state.payrollRates = action.payload;
        },

        getPayrollSettingsSuccess(state, action) {
            state.payrollSettingsLoading = false;
            state.payrollSettingsCollected = true;
            state.payrollSettings = action.payload || {};
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPayrollRates() {
    return async () => {
        dispatch(slice.actions.startPayrollRatesLoading());
        try {
            const response = await axios.get('/api/payroll/rates');
            dispatch(slice.actions.getPayrollRatesSuccess(response.data.rates));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPayrollSettings() {
    return async () => {
        dispatch(slice.actions.startPayrollSettingsLoading());
        try {
            const response = await axios.get('/api/payroll/settings');
            dispatch(slice.actions.getPayrollSettingsSuccess(response.data.payrollSettings[0]));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updatePayrollSettings(settings) {
    return async () => {
        dispatch(slice.actions.startPayrollSettingsLoading());
        try {
            dispatch(slice.actions.getPayrollSettingsSuccess(settings));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}
