import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    companies: [],
    company: null,
    profileComplete: 0,
    loadingProfile: false,
    profileLoaded: false,
    criticalItemsMissing: [],
    itemsMissing: []
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        startLoadingProfile(state) {
            state.loadingProfile = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET COMPANIES
        getCompaniesSuccess(state, action) {
            state.isLoading = false;
            state.companies = action.payload;
        },

        // GET COMPANY
        getCompanySuccess(state, action) {
            state.isLoading = false;
            state.company = action.payload;
        },

        // GET COMPANY
        getCompanyProfileSuccess(state, action) {
            state.loadingProfile = false;
            state.profileLoaded = true;
            state.profileComplete = action.payload.company;
            state.profileState = action.payload.profileState;
            state.criticalItemsMissing = action.payload.criticalItemsMissing;
            state.itemsMissing = action.payload.itemsMissing;
        },

        // RESET STATE
        resetCompanyProfile(state) {
            state.loadingProfile = false;
            state.profileComplete = 0;
            state.loadingProfile = false;
            state.profileLoaded = false;
            state.criticalItemsMissing = [];
            state.itemsMissing = [];
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompanies() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/companies');
            dispatch(slice.actions.getCompaniesSuccess(response.data.companies));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setCompany(company) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCompanySuccess(company));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCompany(_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/companies`, {
                params: {_id},
            });
            dispatch(slice.actions.getCompanySuccess(response.data.companies[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCompanyProfile(_id) {
    return async () => {
        dispatch(slice.actions.startLoadingProfile());
        try {
            const response = await axios.get(`/api/companies/profile`, {
                params: {_id},
            });
            dispatch(slice.actions.getCompanyProfileSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearCompanyProfile(_id) {
    return async () => {
        try {
            dispatch(slice.actions.resetCompanyProfile());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
