import {createContext, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import jwtDecode from 'jwt-decode';
import useAuth from '../hooks/useAuth';
import {dispatch, useSelector} from '../redux/store';
import {clearSession, setSession, setTimeLeft, setUnauthorizedWarning} from '../redux/slices/session';
import {LoadingButton} from "@mui/lab";

const initialSession = {
    initialized: false,
    loading: false,
    role: null,
    Session: null,
};

const SessionContext = createContext({
    Session: initialSession,
    hasAccess: () => false,
}); // component props type

const SessionProvider = ({children}) => {

    const {
        timeLeft,
        checkEveryXMinutes,
        showSessionWarning,
        showUnAuthorized,
        accessToken,
    } = useSelector((state) => state.session);
    const {pathname} = useLocation()

    const {logout, isAuthenticated, loginWithRedirect, refreshUser} = useAuth();
    const navigate = useNavigate();
    const [refreshingUser, setRefreshingUser] = useState(false)

    const checkTimeLeft = useCallback(() => {
        let timeLeft = 60 * 6000;
        try {
            if (accessToken) {
                const claims = jwtDecode(accessToken);
                const now = Math.floor(new Date().getTime() / 1000);
                timeLeft = claims.exp - now;
            }
        } catch (e) {
            console.error(e);
        }

        dispatch(setTimeLeft(timeLeft));
    }, [accessToken]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(setSession(window.localStorage.getItem('accessToken') || null));
        } else {
            dispatch(clearSession());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (timeLeft <= 0 && logout && navigate) {
            logout()
                .then(() => {
                    navigate('/', {replace: true});
                });
        }
    }, [timeLeft, logout, navigate]);


    useEffect(() => {
        const interval = setInterval(() => checkTimeLeft(), checkEveryXMinutes);
        return () => clearInterval(interval);
    }, [checkEveryXMinutes, checkTimeLeft]);

    const resetSession = async () => {
        window.localStorage.setItem('loginUrl', pathname);
        try {
            await loginWithRedirect();
        } catch (error) {
            console.error('resetSession: Error', error);
        }
    };

    const closeSession = async () => {
        await logout();
        dispatch(clearSession());
    };

    const acceptUnauthorized = async () => {
        window.localStorage.setItem('loginUrl', pathname);
        setRefreshingUser(true);
        await refreshUser();
        setRefreshingUser(false);
        dispatch(setUnauthorizedWarning(false));
        await loginWithRedirect();
    };

    const newSession = (e) => {
        dispatch(setSession(e.detail.accessToken));
    }

    useEffect(() => {
        window.addEventListener('setSession', newSession);

        return () => {
            window.removeEventListener('setSession', newSession)
        }
    }, [])

    return (
        <SessionContext.Provider
            value={{}}
        >
            <Dialog open={showSessionWarning}>
                <DialogTitle>Session Expiring</DialogTitle>
                <DialogContent>Your session is going to expire soon. Would you like to continue working?</DialogContent>
                <DialogActions>
                    <Button onClick={resetSession}>Yes, I want more time</Button>
                    <Button onClick={closeSession}>Nope, I'm good</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showUnAuthorized} onClose={acceptUnauthorized}>
                <DialogTitle>Session Timeout</DialogTitle>
                <DialogContent>Your sessions has expired. Please log in.</DialogContent>
                <DialogActions>
                    <LoadingButton loading={refreshingUser} onClick={acceptUnauthorized}>Close</LoadingButton>
                </DialogActions>
            </Dialog>
            {children}
        </SessionContext.Provider>
    );
};

SessionProvider.propTypes = {
    children: PropTypes.node,
};

export {SessionProvider, SessionContext};

