import React from "react";
import {Box} from "@mui/material";
import useNavigation from "../../../hooks/useNavigation";
import {TabsApple} from "../../../components/TabsApple";
import {PermissionsLoading} from "../../../contexts/PermissionsContext";
import useResponsive from "../../../hooks/useResponsive";


export default function TopNavigation() {
    const {fullNavConfig} = useNavigation();

    const lgUp = useResponsive('up', 'lg');

    return (
        <Box ml={2} sx={{minHeight: 10, background: 'transparent'}}>
            <PermissionsLoading topNavMenu message={'Loading permissions'}>
                {lgUp && <TabsApple tabs={fullNavConfig}/>}
            </PermissionsLoading>
        </Box>
    )
}
