import {format, formatDistanceToNow, getTime} from 'date-fns';

// ----------------------------------------------------------------------

// Function to convert a string to ISO UTC
export function convertToISOUTC(dateString) {
    return new Date(dateString).toISOString();
}

export function fDate(date, formatString) {
    return format(new Date(date), formatString || 'MMMM dd, yyyy');
}

export function fDateTime(date) {
    return format(new Date(date), 'PPp');
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function formatUTCTimeCorrectly(dateString) {
    // Directly create a Date object from the ISO string
    const date = new Date(dateString);

    // Format the hours and minutes manually
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
}

// Function to parse time string to Date object
export function parseTimeString(timeString = "") {
    if (typeof timeString === "string") {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        return date;
    }
    return new Date();
}

// Function to format Date object to desired time format
export function formatTime(date) {
    return format(date, 'p'); // Example format: "03:00 PM"
}
