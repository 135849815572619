// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_OPERATIONS_CENTER = '/operations/center';
const ROOTS_BUSINESS_CENTER = '/business/center';
const ROOTS_RESOURCE_CENTER = '/resource/center';
const ROOTS_DRIVER = '/driver';
const ROOTS_REGISTRATION = '/registration';
const ROOTS_CUSTOMER = '/customer';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/billy'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    blog: '/blogs',
    blogPost: (id) => `/blog/post/${id}`,
    maintenance: '/maintenance',
    products: '/products',
    pricing: '/pricing',
    profiles: '/profiles',
    actorProfiles: (type) => `/profiles/${type}`,
    payment: '/payment',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DRIVER = {
    root: ROOTS_DRIVER,
    general: {
        app: path(ROOTS_DRIVER, '/app'),
        ecommerce: path(ROOTS_DRIVER, '/ecommerce'),
        general: path(ROOTS_DRIVER, '/general'),
        analytics: path(ROOTS_DRIVER, '/analytics'),
        banking: path(ROOTS_DRIVER, '/banking'),
        booking: path(ROOTS_DRIVER, '/booking'),
        bonus: path(ROOTS_DASHBOARD, '/bonus/tracker'),
    },
    settings: {
        root: path(ROOTS_DRIVER, '/service/settings')
    },
    notifications: {
        root: path(ROOTS_DRIVER, '/service/notifications')
    },
}

export const PATH_CUSTOMER = {
    root: ROOTS_CUSTOMER,
    general: {
        app: path(ROOTS_CUSTOMER, '/app'),
        ecommerce: path(ROOTS_CUSTOMER, '/ecommerce'),
        general: path(ROOTS_CUSTOMER, '/general'),
        analytics: path(ROOTS_CUSTOMER, '/analytics'),
        banking: path(ROOTS_CUSTOMER, '/banking'),
        booking: path(ROOTS_CUSTOMER, '/booking'),
        bonus: path(ROOTS_DASHBOARD, '/bonus/tracker'),
    },
    coverage: {
        root: path(ROOTS_CUSTOMER, '/coverage'),
        view: path(ROOTS_CUSTOMER, '/coverage/details'),
    },
    document: {
        root: path(ROOTS_CUSTOMER, '/filing/document'),
        list: path(ROOTS_CUSTOMER, '/filing/document/list'),
    },
    contract: {
        root: path(ROOTS_CUSTOMER, '/contract'),
        list: path(ROOTS_CUSTOMER, '/contract/list'),
        edit: path(ROOTS_CUSTOMER, '/contract/edit'),
        new: path(ROOTS_CUSTOMER, '/contract/new'),
        details: path(ROOTS_CUSTOMER, `/contract/details`),
    },
    contractTemplate: {
        root: path(ROOTS_CUSTOMER, '/contract/template'),
        list: path(ROOTS_CUSTOMER, '/contract/template/list'),
        edit: path(ROOTS_CUSTOMER, '/contract/template/edit'),
        new: path(ROOTS_CUSTOMER, '/contract/template/new'),
        details: path(ROOTS_CUSTOMER, `/contract/template/details`),
    },
    location: {
        root: path(ROOTS_CUSTOMER, '/operations/location'),
        list: path(ROOTS_CUSTOMER, '/operations/location/list'),
        new: path(ROOTS_CUSTOMER, '/operations/location/new'),
        edit: path(ROOTS_CUSTOMER, '/operations/location/edit'),
        view: path(ROOTS_CUSTOMER, '/operations/location/view'),
    },
    provider: {
        profile: path(ROOTS_CUSTOMER, `/network/provider/profile`),
        profileBid: path(ROOTS_CUSTOMER, `/network/provider/profile`),
    },
    network: {
        root: path(ROOTS_CUSTOMER, '/service/network'),
        list: path(ROOTS_CUSTOMER, '/service/network/list'),
        new: path(ROOTS_CUSTOMER, '/service/network/new'),
        edit: (id) => path(ROOTS_CUSTOMER, `/service/network/${id}/edit`),
        view: (id) => path(ROOTS_CUSTOMER, `/service/network/${id}`),
    },
    networkBid: {
        root: path(ROOTS_CUSTOMER, '/service/network/bid'),
        list: path(ROOTS_CUSTOMER, '/service/network/bid/list'),
        view: path(ROOTS_CUSTOMER, `/service/network/bid/view`),
    },
    chat: {
        root: path(ROOTS_CUSTOMER, '/communication/chat'),
        new: path(ROOTS_CUSTOMER, '/communication/chat/new'),
        view: (name) => path(ROOTS_CUSTOMER, `/communication/chat/${name}`),
    },
    customerClaims: {
        root: path(ROOTS_CUSTOMER, '/claim/list'),
        new: path(ROOTS_CUSTOMER, '/claim/new'),
        list: path(ROOTS_CUSTOMER, '/claim/list'),
        view: (id) => path(ROOTS_CUSTOMER, `/claim/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/claim/${id}/edit`)
    },
    jobRequest: {
        root: path(ROOTS_CUSTOMER, '/service/job/list'),
        new: path(ROOTS_CUSTOMER, '/service/job/new'),
        customer: path(ROOTS_CUSTOMER, '/service/job/new/customer'),
        list: path(ROOTS_CUSTOMER, '/service/job/list'),
        view: path(ROOTS_CUSTOMER, `/service/job/view`),
        edit: path(ROOTS_CUSTOMER, `/service/job/edit`)
    },
    customerPolicy: {
        root: path(ROOTS_CUSTOMER, '/policy/list'),
        new: path(ROOTS_CUSTOMER, '/policy/new'),
        list: path(ROOTS_CUSTOMER, '/policy/list'),
        view: (id) => path(ROOTS_CUSTOMER, `/policy/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/policy/${id}/edit`)
    },
    invoice: {
        root: path(ROOTS_CUSTOMER, '/invoice'),
        list: path(ROOTS_CUSTOMER, '/invoice/list'),
        new: path(ROOTS_CUSTOMER, '/invoice/new'),
        view: (id) => path(ROOTS_CUSTOMER, `/invoice/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/invoice/${id}/edit`),
    },
    hoo: {
        root: path(ROOTS_CUSTOMER, '/hours-of-operation'),
        list: path(ROOTS_CUSTOMER, '/hours-of-operation/list'),
        new: path(ROOTS_CUSTOMER, '/hours-of-operation/new'),
        view: (id) => path(ROOTS_CUSTOMER, `/hours-of-operation/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/hours-of-operation/${id}/edit`),
    },
    request: {
        root: path(ROOTS_DASHBOARD, '/request'),
        list: path(ROOTS_DASHBOARD, '/request/list'),
        new: path(ROOTS_DASHBOARD, '/request/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/request/${id}/edit`),
        view: (id) => path(ROOTS_DASHBOARD, `/request/${id}`),
    },
}

export const PATH_OPERATIONS_CENTER = {
    root: ROOTS_OPERATIONS_CENTER,
    serviceSink: {
        root: path(ROOTS_OPERATIONS_CENTER, '/service'),
    },
    filing: {
        root: path(ROOTS_OPERATIONS_CENTER, '/filing'),
    },
    communication: {
        root: path(ROOTS_OPERATIONS_CENTER, '/communication'),
    },
    finance: {
        root: path(ROOTS_OPERATIONS_CENTER, '/finance'),
    },
    jobRequest: {
        root: path(ROOTS_OPERATIONS_CENTER, '/service/job/list'),
        new: path(ROOTS_OPERATIONS_CENTER, '/service/job/new'),
        list: path(ROOTS_OPERATIONS_CENTER, '/service/job/list'),
        view: path(ROOTS_OPERATIONS_CENTER, `/service/job/view`),
        edit: path(ROOTS_OPERATIONS_CENTER, `/service/job/edit`)
    },
    document: {
        root: path(ROOTS_OPERATIONS_CENTER, '/filing/document'),
        list: path(ROOTS_OPERATIONS_CENTER, '/filing/document/list'),
        new: path(ROOTS_OPERATIONS_CENTER, '/filing/document/new'),
        view: (id) => path(ROOTS_OPERATIONS_CENTER, `/filing/document/${id}`),
        edit: (id) => path(ROOTS_OPERATIONS_CENTER, `/filing/document/${id}/edit`),
    },
    contract: {
        root: path(ROOTS_OPERATIONS_CENTER, '/filing/contract'),
        list: path(ROOTS_OPERATIONS_CENTER, '/filing/contract/list'),
        edit: path(ROOTS_OPERATIONS_CENTER, '/filing/contract/edit'),
        new: path(ROOTS_OPERATIONS_CENTER, '/filing/contract/new'),
        details: path(ROOTS_OPERATIONS_CENTER, `/filing/cost/details`),
    },
    chat: {
        root: path(ROOTS_OPERATIONS_CENTER, '/communication/chat'),
        new: path(ROOTS_OPERATIONS_CENTER, '/communication/chat/new'),
        create: path(ROOTS_OPERATIONS_CENTER, `/communication/chat/new`),
        view: path(ROOTS_OPERATIONS_CENTER, `/communication/chat/view`),
    },
    payroll: {
        root: path(ROOTS_OPERATIONS_CENTER, '/finance/payroll/list'),
        edit: path(ROOTS_OPERATIONS_CENTER, '/finance/payroll/edit'),
    },
    expense: {
        root: path(ROOTS_OPERATIONS_CENTER, '/finance/expense'),
        list: path(ROOTS_OPERATIONS_CENTER, '/finance/expense/list'),
        new: path(ROOTS_OPERATIONS_CENTER, '/finance/expense/new'),
        view: path(ROOTS_OPERATIONS_CENTER, `/finance/expense/view`),
        edit: path(ROOTS_OPERATIONS_CENTER, `/finance/expense/edit`)
    },
}

export const PATH_RESOURCE_CENTER = {
    root: ROOTS_RESOURCE_CENTER,
    system: {
        root: path(ROOTS_RESOURCE_CENTER, '/system'),
    },
    support: {
        root: path(ROOTS_RESOURCE_CENTER, '/support'),
    },
    integration: {
        root: path(ROOTS_RESOURCE_CENTER, '/system/integration'),
        list: path(ROOTS_RESOURCE_CENTER, '/system/integration/list'),
        new: path(ROOTS_RESOURCE_CENTER, '/system/integration/new'),
        view: (id) => path(ROOTS_RESOURCE_CENTER, `/system/integration/details/${id}`),
        edit: (id) => path(ROOTS_RESOURCE_CENTER, `/system/integration/${id}/edit`),
    },
    request: {
        root: path(ROOTS_DASHBOARD, '/support/request'),
        list: path(ROOTS_DASHBOARD, '/support/request/list'),
        new: path(ROOTS_DASHBOARD, '/support/request/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/support/request/${id}/edit`),
        view: (id) => path(ROOTS_DASHBOARD, `/support/request/${id}`),
    },
}

export const PATH_BUSINESS_CENTER = {
    root: ROOTS_BUSINESS_CENTER,
    operations: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations'),
    },
    hr: {
        root: path(ROOTS_BUSINESS_CENTER, '/hr'),
    },
    pricing: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing'),
    },
    resources: {
        root: path(ROOTS_BUSINESS_CENTER, '/resources'),
    },
    crm: {
        root: path(ROOTS_BUSINESS_CENTER, '/crm'),
    },
    service: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/service'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/service/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/service/new'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/service/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/service/view'),
    },
    hoo: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/hours-of-operation'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/hours-of-operation/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/hours-of-operation/new'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/hours-of-operation/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/hours-of-operation/view'),
    },
    location: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/location'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/location/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/location/new'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/location/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/location/view'),
    },
    fleet: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/list'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/view')
    },
    vehicle: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/new'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/vehicle/view'),
    },
    facility: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/facility'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/facility/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/facility/list'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/facility/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/facility/view')
    },
    insurance: {
        root: path(ROOTS_BUSINESS_CENTER, '/operations/insurance'),
        new: path(ROOTS_BUSINESS_CENTER, '/operations/insurance/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/operations/insurance/list'),
        edit: path(ROOTS_BUSINESS_CENTER, '/operations/insurance/edit'),
        view: path(ROOTS_BUSINESS_CENTER, '/operations/insurance/view')
    },
    part: {
        root: path(ROOTS_BUSINESS_CENTER, '/resources/part/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/resources/part/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/resources/part/list'),
        view: path(ROOTS_BUSINESS_CENTER, `/resources/part/view`),
        edit: path(ROOTS_BUSINESS_CENTER, `/resources/part/edit`)
    },
    content: {
        shop: path(ROOTS_BUSINESS_CENTER, '/resources/content/shop'),
        list: path(ROOTS_BUSINESS_CENTER, '/resources/content/list'),
        checkout: path(ROOTS_BUSINESS_CENTER, '/resources/content/checkout'),
        new: path(ROOTS_BUSINESS_CENTER, '/resources/content/product/new'),
        view: path(ROOTS_BUSINESS_CENTER, `/resources/content/product`),
        edit: (name) => path(ROOTS_BUSINESS_CENTER, `/resources/content/product/${name}/edit`),
        demoEdit: path(ROOTS_BUSINESS_CENTER, '/resources/content/product/nike-blazer-low-77-vintage/edit'),
        demoView: path(ROOTS_BUSINESS_CENTER, '/resources/content/product/nike-air-force-1-ndestrukt'),
        root: path(ROOTS_BUSINESS_CENTER, '/resources/content'),
        purchase: path(ROOTS_BUSINESS_CENTER, '/resources/content/purchase'),
        admin: path(ROOTS_BUSINESS_CENTER, '/resources/content/administration'),
    },
    user: {
        root: path(ROOTS_BUSINESS_CENTER, '/hr/user'),
        new: path(ROOTS_BUSINESS_CENTER, '/hr/user/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/hr/user/list'),
        cards: path(ROOTS_BUSINESS_CENTER, '/hr/user/cards'),
        profile: path(ROOTS_BUSINESS_CENTER, '/hr/user/profile'),
        securityProfile: path(ROOTS_BUSINESS_CENTER, '/hr/user/profile/security'),
        account: path(ROOTS_BUSINESS_CENTER, '/hr/user/account'),
        accountWithTab: (index) => path(ROOTS_BUSINESS_CENTER, `/hr/user/account/${index}`),
        accountWithTabAndOverride: (index, override, linkName) => path(ROOTS_BUSINESS_CENTER, `/hr/user/account/${index}/${override}`),
        accountWithTabAndOverrideLink: (index, override, linkName) => path(ROOTS_BUSINESS_CENTER, `/hr/user/account/${index}/${override}/${linkName}`),
        accountPasswordOverride: path(ROOTS_BUSINESS_CENTER, `/hr/user/account/5/reset`),
        edit: path(ROOTS_BUSINESS_CENTER, `/hr/user/edit`)
    },
    driver: {
        root: path(ROOTS_BUSINESS_CENTER, '/hr/driver/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/hr/driver/new'),
        list: path(ROOTS_BUSINESS_CENTER, '/hr/driver/list'),
        profile: path(ROOTS_BUSINESS_CENTER, '/hr/driver/profile'),
        account: path(ROOTS_BUSINESS_CENTER, '/hr/driver/account'),
        edit: path(ROOTS_BUSINESS_CENTER, `/hr/driver/edit`),
        schedule: path(ROOTS_BUSINESS_CENTER, `/hr/driver/schedule`),
    },
    rate: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/rate'),
        list: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/new'),
        view: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/view'),
        edit: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/edit'),
    },
    cost: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/cost'),
        list: path(ROOTS_BUSINESS_CENTER, '/pricing/cost/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/pricing/cost/new'),
        view: path(ROOTS_BUSINESS_CENTER, '/pricing/cost/view'),
        edit: path(ROOTS_BUSINESS_CENTER, '/pricing/cost/edit'),
    },
    coverage: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/coverage'),
        view: path(ROOTS_BUSINESS_CENTER, '/pricing/coverage/details'),
    },
    invoice: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/invoice'),
        list: path(ROOTS_BUSINESS_CENTER, '/pricing/invoice/list'),
        new: path(ROOTS_BUSINESS_CENTER, '/pricing/invoice/new'),
        view: path(ROOTS_BUSINESS_CENTER, `/pricing/invoice/view`),
        edit: path(ROOTS_BUSINESS_CENTER, `/pricing/invoice/edit`)
    },
    rateWizard: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/wizard'),
        rateWizard: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/wizard'),
    },
    rateArchitect: {
        root: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/architect'),
        rateArchitect: path(ROOTS_BUSINESS_CENTER, '/pricing/rate/architect'),
    },
    billing: {
        root: path(ROOTS_BUSINESS_CENTER, '/billing'),
        list: path(ROOTS_BUSINESS_CENTER, '/billing/list'),
        view: path(ROOTS_BUSINESS_CENTER, `/billing/view`)
    },
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,

    general: {
        welcome: path(ROOTS_DASHBOARD, '/welcome/home'),
        app: path(ROOTS_DASHBOARD, '/operational/general'),
        csi: path(ROOTS_DASHBOARD, '/operational/csi'),
        general: path(ROOTS_DASHBOARD, '/operational/general'),
        analytics: path(ROOTS_DASHBOARD, '/operational/analytics'),
        ecommerce: path(ROOTS_DASHBOARD, '/business/ecommerce'),
        banking: path(ROOTS_DASHBOARD, '/financial/banking'),
        booking: path(ROOTS_DASHBOARD, '/booking'),
        bonus: path(ROOTS_DASHBOARD, '/financial/bonus/tracker'),
    },
    organization: {
        root: path(ROOTS_DASHBOARD, '/user/account'),
    },
    workspace: {
        root: path(ROOTS_DASHBOARD, '/user/account/organization/workspaces'),
    },
    mail: {
        root: path(ROOTS_DASHBOARD, '/mail'),
        all: path(ROOTS_DASHBOARD, '/mail/all'),
    },
    calendar: path(ROOTS_DASHBOARD, '/service/calendar'),
    claims: path(ROOTS_DASHBOARD, '/claims'),
    operational: {
        root: path(ROOTS_DASHBOARD, '/operational'),
    },
    financial: {
        root: path(ROOTS_DASHBOARD, '/financial'),
    },
    business: {
        root: path(ROOTS_DASHBOARD, '/business'),
    },
    company: {
        root: path(ROOTS_DASHBOARD, '/company'),
        new: path(ROOTS_DASHBOARD, '/company/new'),
        list: path(ROOTS_DASHBOARD, '/company/list'),
        cards: path(ROOTS_DASHBOARD, '/company/cards'),
        profile: path(ROOTS_DASHBOARD, '/company/profile'),
        account: path(ROOTS_DASHBOARD, '/company/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/company/${id}/edit`)
    },
    package: {
        root: path(ROOTS_DASHBOARD, '/package'),
        new: path(ROOTS_DASHBOARD, '/package/new'),
        list: path(ROOTS_DASHBOARD, '/package/list'),
        cards: path(ROOTS_DASHBOARD, '/package/cards'),
        profile: path(ROOTS_DASHBOARD, '/package/profile'),
        account: path(ROOTS_DASHBOARD, '/package/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/package/${id}/edit`)
    },
    underwriter: {
        root: path(ROOTS_DASHBOARD, '/underwriter'),
        new: path(ROOTS_DASHBOARD, '/underwriter/new'),
        list: path(ROOTS_DASHBOARD, '/underwriter/list'),
        profile: path(ROOTS_DASHBOARD, '/underwriter/profile'),
        account: path(ROOTS_DASHBOARD, '/underwriter/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/underwriter/${id}/edit`)
    },
    carrier: {
        root: path(ROOTS_DASHBOARD, '/carrier'),
        new: path(ROOTS_DASHBOARD, '/carrier/new'),
        list: path(ROOTS_DASHBOARD, '/carrier/list'),
        profile: path(ROOTS_DASHBOARD, '/carrier/profile'),
        account: path(ROOTS_DASHBOARD, '/carrier/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/carrier/${id}/edit`)
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, '/content/shop'),
        shop: path(ROOTS_DASHBOARD, '/content/shop'),
        list: path(ROOTS_DASHBOARD, '/content/list'),
        checkout: path(ROOTS_DASHBOARD, '/content/checkout'),
        new: path(ROOTS_DASHBOARD, '/content/product/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/content/product/${name}`),
        edit: (name) => path(ROOTS_DASHBOARD, `/content/product/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/content/product/nike-blazer-low-77-vintage/edit'),
        demoView: path(ROOTS_DASHBOARD, '/content/product/nike-air-force-1-ndestrukt'),
    },
    policy: {
        root: path(ROOTS_DASHBOARD, '/policy'),
        list: path(ROOTS_DASHBOARD, '/policy/list'),
        new: path(ROOTS_DASHBOARD, '/policy/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/policy/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/policy/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/policy/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/policy/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    license: {
        root: path(ROOTS_DASHBOARD, '/license'),
        list: path(ROOTS_DASHBOARD, '/license/list'),
        new: path(ROOTS_DASHBOARD, '/license/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/license/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/license/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/license/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/license/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    contractTemplate: {
        root: path(ROOTS_DASHBOARD, '/filing/contract/template'),
        list: path(ROOTS_DASHBOARD, '/filing/contract/template/list'),
        edit: path(ROOTS_DASHBOARD, '/filing/contract/template/edit'),
        new: path(ROOTS_DASHBOARD, '/filing/contract/template/new'),
        details: path(ROOTS_DASHBOARD, `/filing/contract/template/details`),
    },
    dispatch: {
        root: path(ROOTS_DASHBOARD, '/service/dispatch'),
    },
    blog: {
        root: path(ROOTS_DASHBOARD, '/blog'),
        posts: path(ROOTS_DASHBOARD, '/blog/posts'),
        new: path(ROOTS_DASHBOARD, '/blog/new'),
        view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
        demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    },
    role: {
        root: path(ROOTS_DASHBOARD, '/role'),
        list: path(ROOTS_DASHBOARD, '/role/list'),
        new: path(ROOTS_DASHBOARD, '/role/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/role/${id}/edit`),
    },
    opportunity: {
        root: path(ROOTS_DASHBOARD, '/service/opportunity'),
        dashboard: path(ROOTS_DASHBOARD, '/service/opportunity/dashboard'),
        list: path(ROOTS_DASHBOARD, '/service/opportunity/list'),
        new: path(ROOTS_DASHBOARD, '/service/opportunity/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/service/opportunity/${id}/edit`),
    },
    networkBid: {
        root: path(ROOTS_DASHBOARD, '/network/bid'),
        list: path(ROOTS_DASHBOARD, '/network/bid/list'),
        new: path(ROOTS_DASHBOARD, '/network/bid/list'),
        view: path(ROOTS_DASHBOARD, `/network/bid/view`),
    },
    account: {
        root: path(ROOTS_DASHBOARD, '/crm/account'),
        list: path(ROOTS_DASHBOARD, '/crm/account/list'),
        new: path(ROOTS_DASHBOARD, '/crm/account/new'),
        edit: path(ROOTS_DASHBOARD, `/crm/account/edit`),
    },
};

export const PATH_CUSTOMER_PERMISSIONS = {
    [PATH_CUSTOMER.root]: '',

    [PATH_CUSTOMER.general.app]: '',
    [PATH_CUSTOMER.general.general]: '',
    [PATH_CUSTOMER.general.analytics]: '',
    [PATH_CUSTOMER.general.booking]: '',
    [PATH_CUSTOMER.general.ecommerce]: '',
    [PATH_CUSTOMER.general.banking]: '',

    [PATH_CUSTOMER.customerClaims.root]: '',
    [PATH_CUSTOMER.customerClaims.new]: '',
    [PATH_CUSTOMER.customerClaims.list]: '',
    [PATH_CUSTOMER.customerClaims.view]: '',
    [PATH_CUSTOMER.customerClaims.edit]: '',

    [PATH_CUSTOMER.customerPolicy.root]: '',
    [PATH_CUSTOMER.customerPolicy.new]: '',
    [PATH_CUSTOMER.customerPolicy.list]: '',
    [PATH_CUSTOMER.customerPolicy.view]: '',
    [PATH_CUSTOMER.customerPolicy.edit]: '',

    [PATH_CUSTOMER.location.list]: '',
    [PATH_CUSTOMER.location.edit]: '',
    [PATH_CUSTOMER.location.root]: '',
    [PATH_CUSTOMER.location.view]: '',
    [PATH_CUSTOMER.location.new]: '',

    [PATH_CUSTOMER.network.list]: '',
    [PATH_CUSTOMER.network.edit]: '',
    [PATH_CUSTOMER.network.view]: '',
    [PATH_CUSTOMER.network.root]: '',
    [PATH_CUSTOMER.network.new]: '',

    [PATH_CUSTOMER.provider.profile]: '',
    [PATH_CUSTOMER.provider.profileBid]: '',

    [PATH_CUSTOMER.hoo.list]: '',
    [PATH_CUSTOMER.hoo.edit]: '',
    [PATH_CUSTOMER.hoo.view]: '',
    [PATH_CUSTOMER.hoo.root]: '',
    [PATH_CUSTOMER.hoo.new]: '',

    [PATH_CUSTOMER.invoice.list]: '',
    [PATH_CUSTOMER.invoice.edit]: '',
    [PATH_CUSTOMER.invoice.root]: '',
    [PATH_CUSTOMER.invoice.new]: '',
    [PATH_CUSTOMER.invoice.view]: '',

    [PATH_CUSTOMER.coverage.root]: '',
    [PATH_CUSTOMER.coverage.view]: '',

    [PATH_CUSTOMER.document.list]: '',

    [PATH_CUSTOMER.networkBid.list]: ''
}


export const PATH_REGISTRATION = {
    root: ROOTS_REGISTRATION,
    organization: {
        root: path(ROOTS_REGISTRATION, '/organization'),
    },
};
