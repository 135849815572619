// routes
import {useLocation} from "react-router-dom";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import {AlertsProvider} from "./contexts/AlertsContext";
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import {ChartStyle} from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import {ProgressBarStyle} from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import axios from "./utils/axios";
import {SessionProvider} from "./contexts/SessionContext";
import PermissionsProvider from "./contexts/PermissionsContext";
import {NavigationProvider} from "./contexts/NavigationContext";
import Imports from "./components/imports";

// ----------------------------------------------------------------------

export default function App() {
    const {pathname} = useLocation();

    window.onerror = function (error, url, line) {
        if (process.env.REACT_APP_ENV === 'demo' || process.env.REACT_APP_ENV === 'prod') {
            axios.put(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/support/request`, {
                foundInVersion: process.env.REACT_APP_VERSION,
                name: 'Issue captured in app',
                description: `Path: ${url} 
                
                Line: ${line} 
                
                Error: ${error.toString()}
                
                Page: ${window.location.href}`,
                type: "💥 Bug",
                prioritize: 'high'
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })
                .then((response) => {

                })
                .catch((error) => {
                    console.error(error)
                })
        }
    };

    return (
        <ThemeProvider>
            <ThemeColorPresets>
                <ThemeLocalization>
                    <SessionProvider>
                        <PermissionsProvider>
                            <NavigationProvider>
                                <RtlLayout>
                                    <NotistackProvider>
                                        <AlertsProvider>
                                            <MotionLazyContainer>
                                                <ProgressBarStyle/>
                                                <ChartStyle/>
                                                <Imports/>
                                                <Settings isDashboard={pathname.includes('/dashboard')}/>
                                                <ScrollToTop/>
                                                <Router/>
                                            </MotionLazyContainer>
                                        </AlertsProvider>
                                    </NotistackProvider>
                                </RtlLayout>
                            </NavigationProvider>
                        </PermissionsProvider>
                    </SessionProvider>
                </ThemeLocalization>
            </ThemeColorPresets>
        </ThemeProvider>
    );
}
