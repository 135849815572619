import {useEffect, useState} from 'react';
import {dispatch, useSelector} from '../redux/store';
import {find} from "lodash";
import axios from "../utils/axios";
import {setEnvironmentReady} from "../redux/slices/environment";

const useEnvironment = () => {

    const {environmentReady} = useSelector((state) => state.environment);

    const {
        activeWorkspace,
        isLoading: workspaceLoading,
        workspacesCollected,
        workspaces,
    } = useSelector((state) => state.workspaces);

    const {
        activeOrganization,
        isLoading: organizationLoading,
        organizationCollected,
        organizationsCollected,
        organizations,
    } = useSelector((state) => state.organization);

    const buildDataObject = (object) => {
        if (!activeWorkspace) {
            throw new Error('Use Environment Build Data Object: Active Workspace is not set and is required to build a data object.');
        }

        if (!activeOrganization) {
            throw new Error('Use Environment Build Data Object: Active Organization is not set and is required to build a data object');
        }

        return {...object, organization: activeOrganization._id, workspace: activeWorkspace._id};
    };

    const getOrgRole = (person) => {
        let data = {
            role: '',
            organization: ''
        }
        if (environmentReady && person?.permissions) {
            const permission = find(person.permissions, (permission) => permission.id === activeOrganization._id || permission.id === activeOrganization.parentOrganization || find(activeOrganization.subOrganizations, {_id: permission.id}))
            const {
                role,
                organization,
            } = permission || {};
            data = {
                role,
                organization
            }
        }
        return data;
    }

    const getAxiosHeaders = () => axios.defaults.headers.common

    const [lastOrgId, setLastOrgId] = useState(null);

    useEffect(() => {
        const axiosHeaders = getAxiosHeaders();

        const orgDataCollected = organizationsCollected && organizationCollected && Boolean(!!activeOrganization) && !organizationLoading;

        const headersSet = Boolean(!!axiosHeaders['x-dolooma-organization']) && Boolean(!!axiosHeaders['x-dolooma-workspace']);

        const workspaceCollected = Boolean(!!activeWorkspace) && workspacesCollected && !workspaceLoading

        const orgChange = lastOrgId !== activeOrganization?._id || null;
        // console.log({orgChange, lastOrgId, activeOrganization: activeOrganization?._id || null})
        if (orgChange && headersSet && orgDataCollected && workspaceCollected) {
            setLastOrgId(activeOrganization?._id || null)
            dispatch(setEnvironmentReady((headersSet && orgDataCollected && workspaceCollected)));
        }

    }, [lastOrgId, setLastOrgId, organizationsCollected, organizationCollected, activeOrganization, workspacesCollected, organizationLoading, workspaceLoading, activeWorkspace]);

    const handleEnvironmentReadyChange = (value) => dispatch(setEnvironmentReady(value));

    const getCurrentOrgDetails = () => activeOrganization;

    const checkOfferings = (...rest) => !Array.from(rest).map((arg) => activeOrganization?.offerings ? activeOrganization.offerings[arg] : false).includes(false);

    return {
        getOrgRole,
        buildDataObject,
        checkOfferings,
        getCurrentOrgDetails,
        workspaces,
        organizations,
        workspace: activeWorkspace?._id,
        organization: activeOrganization?._id,
        environmentReady,
        setEnvironmentReady: handleEnvironmentReadyChange
    };
};

useEnvironment.propTypes = {};

export default useEnvironment;
