import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Link, Typography} from '@mui/material';
// components
import MyAvatar from '../../../components/MyAvatar';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import {PATH_BUSINESS_CENTER} from '../../../routes/paths';
import usePermissions from '../../../hooks/usePermissions';
import {useCallback} from "react";
import {useSelector} from "../../../redux/store";
import {useFlags} from "launchdarkly-react-client-sdk";
// config

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function NavbarAccount({isCollapse}) {
    const {user} = useAuth();

    const {roleAccessOwner} = useFlags()

    const {permissions} = usePermissions();

    const {organization} = useSelector((state) => state.organization);

    const getRole = useCallback(() => permissions?.role?.name || 'No Assigned Role', [permissions]);

    const accountComponent = (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    bgcolor: 'transparent',
                }),
            }}
        >
            <MyAvatar/>

            <Box
                sx={{
                    ml: 2,
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            duration: theme.transitions.duration.shorter,
                        }),
                    ...(isCollapse && {
                        ml: 0,
                        width: 0,
                    }),
                }}
            >


                {!isCollapse && <Box
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 160
                    }}>
                    <Typography variant='subtitle2' noWrap>
                        {user?.nickname || user?.name || `${user?.firstName} + ${user?.lastName}`}
                    </Typography>
                </Box>}

                {!isCollapse && <Box
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 160
                    }}>
                    <Typography variant='body2' noWrap sx={{color: 'text.secondary'}}>
                        {getRole()}
                    </Typography>
                </Box>}

                {!isCollapse && <Box
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 160
                    }}>
                    <Typography variant='body2' noWrap sx={{color: 'text.secondary'}}>
                        {organization?.name || 'No Assigned Company'}
                    </Typography>
                </Box>}
            </Box>
        </RootStyle>
    )

    if (!roleAccessOwner) {
        return (
            <>
                {accountComponent}
            </>
        );
    }

    return (
        <>
            <Link underline='none' color='inherit' component={RouterLink} to={PATH_BUSINESS_CENTER.user.account}>
                {accountComponent}
            </Link>
        </>
    );
}
